.markdown-view strong {
  font-weight: bolder !important;
}

.markdown-view blockquote {
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
  margin-inline-start: 1rem;
  margin-inline-end: 1rem;
}

.markdown-view blockquote p {
  margin-bottom: 0;
}

.markdown-view p {
  font-weight: normal;
  line-height: normal;
}
