//
// Nav
//

@import "../core/navs/nav";
@import "../core/navs/nav-pills";

.nav-tabs .nav-item {
  cursor: pointer;
}
