.info-icon {
  margin-top: -0.2rem;
}

label .info-icon {
  margin-left: 0.25rem;
}

.help-icon {
  cursor: pointer;
}

.help-icon-popover {
  max-width: 30rem !important;
}
.help-icon-popover {
  max-width: 30rem !important;
}
