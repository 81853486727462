@import "styles/argon-dashboard-react.scss";
@import "~@fortawesome/fontawesome-free/css/all.css";

html {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

header .nav-tabs,
header .breadcrumb-bar {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #dee2e6;
}

.nav-link {
  padding: 0.5rem 1rem;
}

header .nav-tabs {
  text-align: center;
  justify-content: center;
  padding-top: 0.3em;
}

.app-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.modal-panel {
  width: 100%;
  max-width: 40em;
  box-shadow: 0 0.5em 1.5em rgba(0, 0, 0, 0.15);
}

.modal-panel.wide {
  width: 100%;
  max-width: 65em;
}

.card {
  margin-bottom: 2rem;
}

.row {
  .card {
    margin-bottom: 0.75rem;
    box-shadow: 6px 0 20px 0 #ced4da;
    @media (min-width: 768px) {
      margin-bottom: 1.3rem;
    }
  }
}

.italics {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.relative {
  position: relative;
}

.pull-right {
  float: right;
}

button.btn {
  min-width: 6.7rem;
}

a.btn {
  min-width: 6.7rem;
}

.btn-toolbar {
  & > button {
    margin-left: 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }

  & > .btn-group {
    margin-left: 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.card-header {
  h1 {
    color: $green;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 0;
  }

  & h5 {
    margin-bottom: 0;
  }
}

.breadcrumbs__crumb--active {
  pointer-events: none;
  color: #212529;
}

.card-no-margin-bottom {
  margin-bottom: -1.5rem;
}

.form-control {
  ~ .invalid-feedback {
    visibility: hidden;
    display: block;
    margin-top: 0.25rem;
  }

  &.is-invalid ~ .invalid-feedback {
    visibility: visible;
  }
}

.dropdown-item {
  cursor: pointer;
}

.text-white-link {
  color: white;

  &:hover {
    color: $green;
  }
}

.line-height-1 {
  line-height: 1;
}

.no-before-after {
  &:after,
  &:before {
    content: none !important;
  }
}

.badge {
  color: $gray-800;
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 2.3rem !important;
}

.VirtualizedTreeSelectTooltip {
  word-break: normal !important;
}

.input-group-button {
  z-index: 0 !important;
}

.list-item-remove-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}

.validation-feedback {
  display: block !important;
  visibility: visible !important;
}

.react-select__control {
  height: 36px;
  max-height: 36px;
  min-height: 36px !important;
}

.react-select__multi-value {
  font-size: 0.875rem !important;
  padding: 0.125rem 0.25rem !important;
}

.react-select__multi-value .badge {
  margin-right: 0.25rem;
}

td ul {
  padding-left: 1.2rem;
  margin-top: 0;
  margin-bottom: 0;
}
