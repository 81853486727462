//
// Cards
//

@import "../core/cards/card";
@import "../core/cards/card-profile";
@import "../core/cards/card-blockquote";
@import "../core/cards/card-animations";
@import "../core/cards/card-stats";

.card-header-basic-info {
  border-bottom: 0;
  padding-bottom: 0;
}

.card-body-basic-info {
  margin-left: 1rem;
  padding-top: 1rem;
}
