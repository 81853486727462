//
// Button
//

.btn-success {
  color: $black;
}

.btn-link {
  box-shadow: none !important;
}

@import "../core/buttons/button";
@import "../core/buttons/button-icon";
@import "../core/buttons/button-brand";
