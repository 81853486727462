@import "../../styles/custom/colors";

.mask {
  position: fixed !important;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(245, 245, 245, 0.6);
  z-index: 1051;
}

/* This mask covers a container instead of the whole viewport */
.mask-container {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(245, 245, 245, 0.6);
  z-index: 101;
}

.spinner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 50px;
  text-align: center;
  vertical-align: middle;

  .spinner-message {
    color: $primary;
    font-size: 11px;
  }
}

.without-text {
  @extend .spinner-container;
  height: 32px;
}
