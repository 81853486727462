$navbar-vertical-width-expanded: 240px !default;
$navbar-vertical-width-collapsed: 60px !default;
$footer-height: 45px !default;
$header-height: 60px !default;
$grid-gutter-width: 20px !default;

$xs: 0 !default;
$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
